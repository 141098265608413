// import i18n from '@/i18n/index'

export default {
  general: [
    { prop: 'charge_point' },
    { prop: 'qr_code' },
    { prop: 'current_type' },
    { prop: 'hardware_status' },
    { prop: 'network_id' },
    { prop: 'display_max_power' },
    { prop: 'max_current' },
    { prop: 'allows_reservation' },
    { prop: 'phase' },
    { prop: 'phase_rotation' },
    { prop: 'input_voltage' }
  ],
  connectors: [
    { prop: 'evse_connectors' }
  ]
}
