<template>
  <div class="evsesManager">
    <el-row :gutter="20" class="detail__row">
      <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <el-card class="detail__top detail__right">
          <div class="right__box">
            <span class="detail__title">System Status:</span>
            <span class="detail__status">Enabled</span>
            <span class="detail__title margin__top12">Hardware Status:</span>
            <span class="detail__error">{{ custName.hardware_status && custName.hardware_status.value || '--'}}</span>
            <span class="detail__title margin__top12">Last session:</span>
            <div class="detail__time">
              <span class="first" v-if="custName.last_session && custName.last_session.value">{{custName.last_session.value}}</span>
              <!-- <span class="sec">(2019-04-24)</span> -->
            </div>
          </div>
          <div class="left__box">
            <div>
              <span class="detail__title">No Active session</span>
              <!-- <span class="left__label" @click="forwardToPage(item)">{{item.name}}</span> -->
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <el-card class="detail__top">
          <div class="detail__select">
            <el-select v-model="value" size="medium" placeholder="please select">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="detail__box margin__top20">
            <span class="detail__title">Charging Sessions:</span>
            <span class="detail__content" v-if="custName.charge_sessions_count">{{custName.charge_sessions_count.value}}</span>
          </div>
          <div class="detail__box margin__top12">
            <span class="detail__title">Total Energy:</span>
            <span class="detail__content" v-if="custName.total_energy">{{`${custName.total_energy.value} kWhs`}}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="operate__wrapper">
      <span>EVSE</span>
      <div>
        <el-select v-model="value1" placeholder="Select Action">
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      <el-button type="primary" icon="el-icon-caret-right" @click="handleOperate"></el-button>
      </div>
    </div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="General" name="general"></el-tab-pane>
        <el-tab-pane label="Connectors" name="connectors"></el-tab-pane>
      </el-tabs>
      <custome-table @forwardToPage="forwardToPage" tableType="object" :custSpan="6" :data="custName" :dataItem="jsonData[activeName]"></custome-table>
    </el-card>

    <el-dialog title="Set Charging Profile" v-if="dialogFormVisible" width="80%" :visible.sync="dialogFormVisible">
      <chargingSchedule></chargingSchedule>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="hanleMessage(); dialogFormVisible = false">Set</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import apis from '@/services'
import customeTable from '@/components/customeTable'
import jsonData from './jsonData'
import { mixin } from '@/mixins/viewDiolog'
import chargingSchedule from '@/views/chargingPointManager/add/component/chargingSchedule.vue'

export default {
  components: { customeTable, chargingSchedule },
  mixins: [mixin],
  data () {
    return {
      custName: {},
      activeName: 'general',
      jsonData,
      dialogFormVisible: false,
      options: [{
        value: 'm',
        label: 'Month To Date'
      }],
      value: 'm',
      options1: [{
        value: 'Start Session',
        label: 'Start Session'
      }, {
        value: 'Reserve',
        label: 'Reserve'
      }, {
        value: 'Unlock',
        label: 'Unlock'
      }, {
        value: 'Set Charging Profile',
        label: 'Set Charging Profile'
      }, {
        value: 'Get Composite Schedule',
        label: 'Get Composite Schedule'
      }, {
        value: 'Triggle Message',
        label: 'Triggle Message'
      }, {
        value: 'Export EVSEs',
        label: 'Export EVSEs'
      }, {
        value: 'Change Avaliability',
        label: 'Change Avaliability'
      }],
      value1: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await apis.AssetApi.getEvsesdtails(this.$route.params.id)
      if (res.status === 200) {
        this.custName = { ...res.data.evse }
        // network_id: { name: 'Network ID', value: '2'  }
      }
      this.$store.state.app.loading.close()
    },
    async handleClick (type, data) { },
    forwardToPage (data, name) {
      // console.log(data)
      if (data.model_name && data.model_name === 'charge_point') {
        this.$router.push({
          name: 'chargingPointDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    handleOperate () {
      if (this.value1 === '') {
        this.$message({
          message: 'Please Select Action.',
          type: 'error'
        })
        return
      }
      if (this.value1 === 'Set Charging Profile') {
        this.dialogFormVisible = true
      } else {
        this.hanleMessage()
      }
    },
    hanleMessage () {
      this.$store.state.app.loading = this.$loading(loading)
      setTimeout(() => {
        this.$store.state.app.loading.close()
        this.$message({
          message: 'The EVSE is currently unavailable.',
          type: 'error'
        })
      }, 500)
    }
  }
}
</script>
<style lang="scss" scope>
.evsesManager {
.margin__top12 {
    margin-top: 12px
  }
  .margin__top20 {
    margin-top: 24px
  }
  .detail__row {
    .el-col {
      margin-bottom: 20px;
    }
    .detail__top {
      border-radius: 8px;
      min-height: 200px;
      .detail__title {
        color: #999;
        font-size: 1rem;
        margin-bottom: 2px;
        font-variant-numeric: tabular-nums;
      }
    }
    .detail__right {
      .el-card__body {
        display: flex;
      }
      .right__box {
        display: flex;
        flex-direction: column;
        flex: 1;
        .detail__status {
          font-size: 1.2rem;
          font-weight: 600;
          color: rgb(24, 145, 104);
          line-height: 20px;
        }
        .detail__time {
          display: flex;
          align-items: center;
          .first {
            font-size: 1rem;
            display: inline-block;
            border-radius: 20px;
            color: #409eff;
            font-weight: 500;
            padding: 4px 8px;
          }
          .sec {
            font-size: 1rem;
            margin-left: 4px;
            color: #666;
          }
        }
        .detail__error {
          font-size: 1.2rem;
          color: #666;
        }
      }
      .left__box {
        flex-shrink: 0;
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-left: 1.5px solid #EBEEF5;
        padding-left: 16px;
        > div {
          margin-bottom: 2px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .left__label {
          font-weight: 500;
          color: #409eff;
          margin-left: 4px;
          cursor: pointer;
        }
        @media screen and (max-width: 1200px) {
          width: 200px;
        }
      }
    }
    .detail__select {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: end;
      .el-select {
        width: 150px;
      }
    }
    .detail__content {
      font-weight: 600;
    }
    .detail__box {
      display: flex;
      flex-direction: column;
    }
  }
  .operate__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding-left: 5px;
    .el-select {
      // border: 1.5px solid #409eff;
      border-radius: 6px;
      width: 200px;
      margin-right: 4px;
    }
    .el-select .el-input.is-focus .el-input__inner {
      border-color: #409eff;
    }
    .el-select .el-input__inner:focus {
      border-color: #409eff;
    }
  }
}
</style>
